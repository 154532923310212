<template>
  <div class="pb-4">
    <b-row>
      <b-col md="12 padding-top-16">
        <p class="text-center">
          <span
            class="aiops_neutral_blue_heading1"
            style="text-transform: uppercase;"
          >
            YOUR CATEGORY
          </span>
        </p>
        <p class="text-center">
          <span
            class="aiops_paragraph_6f9ca8"
          >
            Pick a category field from the menu on the left and define the settings for the classification model for each level of granularity.
          </span>
        </p>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col
        class="col-md-3 mb-2 pl-0 padding-top-64"
      >
        <div
          class="col-md-12 pl-0"
        >
          <b-form-group
            class="aiops_label_20"
            label-for="typeIssue"
          >
            <span>
              Category field
            </span>
            <b-form-select
              id="typeIssue"
              v-model="selectedCategory"
              :options="optionsIssue"
              @change="dataModelManager()"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row> -->
    <b-row
      v-if="!verifyTicketEmpty"
    >
      <b-col
        md="12"
        class="p-2 aiops_color_box"
      >
        <h4
          class="aiops_mineral_green_heading4 mb-1"
        >
          APPLICABLE MODELS
        </h4>
        <b-table
          id="modelTable"
          ref="modelTable"
          refreshed
          responsive
          selectable
          noCollapse
          tbody-tr-class="table-border-bottom"
          thead-class="colorHead"
          tbody-class="colorBody"
          :busy.sync="isBusyCat"
          :select-mode="selectMode"
          :current-page="currentPage"
          :items="getTicketsItems"
          :fields="fieldsModel"
          @row-clicked="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #head()="data">
            <span class="colorHead">{{ data.label.toUpperCase() }}</span>
          </template>
          <template #cell()="data">
            <span class="colorHead">{{ data.value }}</span>
          </template>
          <template
            v-slot:cell(actions)="row"
            class="aiops_model_table_col_action text-right"
          >
            <b-form-checkbox
              v-model="row.item.enabled"
              class="aiops_switch"
              name="check-button"
              switch
              @change="switchModel(row.item.name, row.item.enabled, 'CATEGORY')"
            >
              <span
                class="aiops_paragraph_6f9ca8"
              >
                {{ row.item.enabled ? 'On' : 'Off' }}
              </span>
            </b-form-checkbox>
          </template>
          <template
            slot="row-details"
            slot-scope="row"
          >
            <b-row
              class="tableDetailModel"
            >
              <table
                bordered
                responsive
              >
                <thead
                  class="colorHead"
                >
                  <tr>
                    <th
                      v-for="(item, index) in fieldsDetail"
                      :key="index"
                      class="colorHead"
                    >
                      {{ item.label }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="colorBody"
                >
                  <tr
                    v-for="(item, index) in row.item.classes"
                    :key="index"
                    class="colorBody"
                  >
                    <td>{{ item.modelClass }}</td>
                    <td>
                      <span class="aiops_Models__detail_align_inline mr-1">
                        <b-form-checkbox
                          v-model="item.reclassify"
                          class="aiops_switch"
                          name="check-button"
                          switch
                          @change="modifyModel(item, 'CATEGORY')"
                        >
                          <span
                            class="aiops_paragraph_6f9ca8"
                          >
                            {{ item.reclassify ? 'On' : 'Off' }}
                          </span>
                        </b-form-checkbox>
                      </span>
                    </td>
                    <td>
                      <div>
                        <b-row>
                          <b-col
                            class="col-md-7 aiops_Models__detail_align_inline"
                            style="padding-top: 10px;"
                          >
                            <vue-slider
                              v-model="item.confidence"
                              :direction="dir"
                              @drag-end="modifyModel(item, 'CATEGORY')"
                            />
                          </b-col>
                          <b-col
                            class="col-md-5 aiops_Models__detail_align_inline"
                            style="border: #ebe9f1 solid 2px!important; border-radius: 10%;"
                          >
                            <p
                              class="aiops_Models__detail_align_inline aiops_Models__sliderBar_value mb-0"
                              style="margin-top: 5px; margin-bottom: 5px !important;"
                            >
                              {{ item.confidence }}%
                            </p>
                          </b-col>
                        </b-row>
                      </div>
                    </td>
                    <td>
                      <span class="aiops_Models__detail_align_inline mr-1">
                        <b-form-checkbox
                          v-model="item.reassign"
                          class="aiops_switch"
                          name="check-button"
                          :disabled="!item.reclassify"
                          switch
                          @change="modifyReassign(item, 'CATEGORY')"
                        >
                          <span
                            class="aiops_paragraph_6f9ca8"
                          >
                            {{ item.reassign ? 'On' : 'Off' }}
                          </span>
                        </b-form-checkbox>
                      </span>
                    </td>
                    <td>
                      <div>
                        <b-form-select
                          id="dataSource"
                          v-model="item.reassignGroup"
                          :disabled="!item.reassign"
                          :options="optionsGroup"
                          @change="modifyModel(item, 'CATEGORY')"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="optionsSubcategory.length > 0 && verifyEnabledModel"
        class="col-md-3 pl-0 padding-top-48"
      >
        <div
          class="col-md-12 pl-0"
        >
          <!-- dark -->
          <b-form-group
            class="aiops_label_14"
            label-for="typeIssue"
          >
            <span>
              Subcategory field
            </span>
            <b-form-select
              id="typeIssue"
              v-model="selectedSubcategory"
              :options="getSubcategoriesOptions"
              @change="dataModelSubcategory()"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="!verifyTicketEmptySubcategory && verifyEnabledModel"
        class="col-md-12 p-2 mt-2 aiops_color_box"
      >
        <h4
          class="aiops_mineral_green_heading4 mb-1"
        >
          APPLICABLE MODELS - {{ modelName }}
        </h4>
        <b-table
          id="subcategoryModelTable"
          ref="subcategoryModelTable"
          refreshed
          responsive
          selectable
          thead-class="colorHead"
          tbody-class="colorBody"
          :busy.sync="isBusySubCat"
          :select-mode="selectMode"
          :items="getTicketsItemsSubcategory"
          :fields="fieldsModel"
          @row-clicked="onRowSelectedSubcategory"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template
            v-slot:cell(actions)="row"
            class="aiops_model_table_col_action text-right"
          >
            <b-form-checkbox
              v-model="row.item.enabled"
              class="aiops_switch"
              name="check-button"
              switch
              @change="switchModel(row.item.name, row.item.enabled, 'SUBCATEGORY')"
            >
              <span
                class="aiops_paragraph_6f9ca8"
              >
                {{ row.item.enabled ? 'On' : 'Off' }}
              </span>
            </b-form-checkbox>
          </template>
          <template
            slot="row-details"
            slot-scope="row"
          >
            <b-row
              class="tableDetailModel"
            >
              <table
                responsive
              >
                <thead
                  class="colorHead"
                >
                  <tr>
                    <th
                      v-for="(item, index) in fieldsDetail"
                      :key="index"
                      class="colorHead"
                    >
                      {{ item.label }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="colorBody"
                >
                  <tr
                    v-for="(item, index) in row.item.classes"
                    :key="index"
                    class="colorBody"
                  >
                    <td>{{ item.modelClass }}</td>
                    <td>
                      <span class="aiops_Models__detail_align_inline mr-1">
                        <b-form-checkbox
                          v-model="item.reclassify"
                          class="aiops_switch"
                          name="check-button"
                          switch
                          @change="modifyModel(item, 'SUBCATEGORY')"
                        >
                          <span
                            class="aiops_paragraph_6f9ca8"
                          >
                            {{ item.reclassify ? 'On' : 'Off' }}
                          </span>
                        </b-form-checkbox>
                      </span>
                    </td>
                    <td>
                      <div>
                        <b-row>
                          <b-col
                            class="col-md-7 aiops_Models__detail_align_inline"
                            style="padding-top: 10px;"
                          >
                            <vue-slider
                              v-model="item.confidence"
                              :direction="dir"
                              @drag-end="modifyModel(item, 'SUBCATEGORY')"
                            />
                          </b-col>
                          <b-col
                            class="col-md-5 aiops_Models__detail_align_inline"
                            style="border: #ebe9f1 solid 2px!important; border-radius: 10%;"
                          >
                            <p
                              class="aiops_Models__detail_align_inline aiops_Models__sliderBar_value mb-0"
                              style="margin-top: 5px; margin-bottom: 5px !important;"
                            >
                              {{ item.confidence }}%
                            </p>
                          </b-col>
                        </b-row>
                      </div>
                    </td>
                    <td>
                      <span class="aiops_Models__detail_align_inline mr-1">
                        <b-form-checkbox
                          v-model="item.reassign"
                          class="aiops_switch"
                          :disabled="!item.reclassify"
                          name="check-button"
                          switch
                          @change="modifyReassign(item, 'SUBCATEGORY')"
                        >
                          <span
                            class="aiops_paragraph_6f9ca8"
                          >
                            {{ item.reassign ? 'On' : 'Off' }}
                          </span>
                        </b-form-checkbox>
                      </span>
                    </td>
                    <td>
                      <div>
                        <b-form-select
                          id="dataSource"
                          v-model="item.reassignGroup"
                          :disabled="!item.reassign"
                          :options="optionsGroup"
                          @change="modifyModel(item, 'SUBCATEGORY')"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <modal-aiops-component
      :logo="logo"
      :hideCloseButton="true"
      :evoidCloseOnBackdrop="true"
      :sizeModal="sizeModal"
    >
      <template v-slot:body>
        <b-row>
          <validation-observer
            ref="reassignGroup"
          >
            <b-form>
              <div
                class="row col-md-12"
              >
                <b-col
                  class="col-sm-12 col-md-12 pl-2"
                >
                  <b-form-group
                    class="aiops_label_form_bold"
                  >
                    <label
                      for="group"
                      class="aiops_label_14_3e7282_bold"
                    >
                      Reassignation group
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="reassignation-group"
                      rules="required"
                    >
                      <b-form-select
                        id="resssignation-group"
                        v-model="newModel.reassignGroup"
                        :options="optionsGroup"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </div>
            </b-form>
          </validation-observer>
        </b-row>
        <b-row
          class="col-md-12 padding-bottom-24 padding-top-24 pr-0"
        >
          <b-col
            class="col-md-12 text-right"
          >
            <b-button
              class="ibop-salmon-pearl-button"
              @click="closeModal"
            >
              <span>Cancel</span>
            </b-button>
            <b-button
              class="ibop-mineral-green-button ml-1"
              @click="accept"
            >
              <b-img
                class="mintui_arrow_right_circle_light"
              />
              <span class="align-middle aiops_label_white_14">Reassign</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </modal-aiops-component>
  </div>
</template>

<script>
import {
  BTable,
  BTr,
  BTd,
  BTh,
  BTfoot,
  BTbody,
  BThead,
  BButton,
  BRow,
  BCol,
  BImg,
  BDropdown,
  BDropdownItem,
  BBadge,
  BJumbotron,
  BFormGroup,
  BFormSelect,
  BForm,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BSpinner,
  BTableLite,
  VBModal,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import { resolutionGroup } from '@/@core/utils/aiops/resolutionGroup'
import '@/assets/scss/aiops_slider/aiops_slider.css'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/aiops/useJwt'
import utilsAiops from '@/@core/utils/aiops/utils'
import store from '@/store'
import modelManagerStoreModule from './modelManagerStoreModule'
import ModalAiopsComponent from '../../../../components/modal/modal-product/ModalAiopsComponent.vue'

const {
  getSubcategories,
  removeAccents,
  loadingProccessSpinner,
  loading,
} = utilsAiops()
// const resolutionGroups = resolutionGroup
const MODELCLASS = 'modelClass'
const TYPEMODELCATEGORY = 'CATEGORY'
// const TYPEMODELSUBCATEGORY = 'SUBCATEGORY'

export default {
  components: {
    BTable,
    BTr,
    BTd,
    BTh,
    BTfoot,
    BTbody,
    BThead,
    BButton,
    BRow,
    BCol,
    BImg,
    BDropdown,
    BDropdownItem,
    BBadge,
    BJumbotron,
    BFormGroup,
    BFormSelect,
    BForm,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BSpinner,
    VueSlider,
    BTableLite,
    ModalAiopsComponent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      // eslint-disable-next-line global-require
      logo: require('@/assets/images/aiops/logo/business_platform_logo_mini.svg'),
      value: 50,
      dir: 'ltr',
      selected: null,
      optionsGroup: [],
      selectedCategory: 'AREA',
      optionsIssue: [
        { value: null, text: 'Type issue' },
        { value: 'AREA', text: 'AREA' },
      ],
      optionsSubcategory: [],
      selectedSubcategory: null,
      modelName: null,
      perPage: 1,
      perPageSubcategy: 1,
      totalRows: 1,
      currentPage: 1,
      ticket: {},
      fieldsModel: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'last_trained_on',
          label: 'Last trained on',
        },
        {
          key: 'accuracy',
          Label: 'Accuracy',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      fieldsDetail: [
        {
          key: 'modelClass',
          label: 'Model Class',
        },
        {
          key: 'reclassify',
          label: 'Automatically Reclassify',
        },
        {
          key: 'confidence',
          label: 'Minimum Confidence',
        },
        {
          key: 'reassign',
          label: 'Re assign',
        },
        {
          key: 'reassignGroup',
          label: 'Reassignation Group',
        },
      ],
      items: [],
      isBusyCat: false,
      itemsSubcategory: [],
      isBusySubCat: false,
      itemsDetail: [],
      models: [],
      selectMode: 'single',
      activeButton: true,
      sizeModal: 'sm',
    }
  },
  computed: {
    getTicketsItems() {
      return this.items
    },
    getTicketsItemsSubcategory() {
      return this.itemsSubcategory
    },
    verifyTicketEmpty() {
      return this.verifyTicket()
    },
    verifyEnabledModel() {
      return this.verifyModelsEnabled()
    },
    getSubcategoriesOptions() {
      return this.optionsSubcategory
    },
    verifyTicketEmptySubcategory() {
      return this.verifyTicketSubcategory()
    },
  },
  created() {
    if (!this.$ability.can('aiopsuser')) {
      this.$router.push({ name: 'aiops-home' })
    }

    const MODEL_MANAGER_STORE_MODULE_NAME = 'aiops-model-manager'

    // Register module
    if (!store.hasModule(MODEL_MANAGER_STORE_MODULE_NAME)) store.registerModule(MODEL_MANAGER_STORE_MODULE_NAME, modelManagerStoreModule)

    // this.verifyToken()
    this.getAssignmentGroup()
    this.verifyTicket()
    this.verifyTicketSubcategory()
    this.welcomeAssist()
  },
  mounted() {
    loading(false)
  },
  methods: {
    closeModal() {
      if (this.typeModel === TYPEMODELCATEGORY) {
        this.newModel.reassign = false
        this.items = this.modifyDataTable(this.items, this.newModel)
      } else {
        this.newModel.reassign = false
        this.itemsSubcategory = this.modifyDataTable(this.itemsSubcategory, this.newModel)
      }
      this.$bvModal.hide('modal-aiops')
    },
    modifyDataTable(tableData, newModel) {
      const newTableData = tableData
      newTableData.forEach((item, index) => {
        const newItemModel = item
        if (newItemModel.name === newModel.modelName) {
          // eslint-disable-next-line no-underscore-dangle
          newItemModel._showDetails = true
          newItemModel.classes.forEach((classItem, indexClass) => {
            const model = classItem
            if (model.modelClass === newModel.modelClass) {
              newTableData[index].classes[indexClass].reassign = newModel.reassign
              newTableData[index].classes[indexClass].reassignGroup = newModel.reassignGroup
            }
          })
        }
      })
      return newTableData
    },
    modifyReassign(datamodel, typeModel) {
      if (!datamodel.reassign) {
        this.modifyModel(datamodel)
        return
      }
      // eslint-disable-next-line no-undef
      this.newModel = structuredClone(datamodel)
      this.typeModel = typeModel
      if (datamodel.reassign) {
        this.$bvModal.show('modal-aiops')
      }
    },
    accept() {
      this.$refs.reassignGroup.validate().then(success => {
        if (success) {
          this.modifyModel(this.newModel)
          if (this.typeModel === TYPEMODELCATEGORY) {
            this.items = this.modifyDataTable(this.items, this.newModel)
          } else {
            this.itemsSubcategory = this.modifyDataTable(this.itemsSubcategory, this.newModel)
          }
          this.$bvModal.hide('modal-aiops')
        } else {
          this.errorMessage('The Reassignation group field is required')
        }
      })
    },
    modifyModel(datamodel) {
      const modelToModify = datamodel
      modelToModify.reassign = modelToModify.reclassify ? modelToModify.reassign : false
      modelToModify.reassignGroup = modelToModify.reassign ? modelToModify.reassignGroup : null
      loadingProccessSpinner(true)
      useJwt.modifyModel(modelToModify)
        .then(response => {
          if (response) {
            const variant = 'success'
            this.$bvToast.toast(`The model class ${datamodel.modelClass} was correctly modified`, {
              title: 'Model Manager',
              variant,
              solid: true,
            })
            loadingProccessSpinner(false)
          }
        })
        .catch(() => {
          this.errorMessage('Unable to modify model')
          loadingProccessSpinner(false)
        })
    },
    switchModel(modelName, value, typeModel) {
      loadingProccessSpinner(true)
      useJwt.switchModel(modelName)
        .then(response => {
          const { data } = response
          if (typeModel === TYPEMODELCATEGORY) {
            this.isBusyCat = false
            const modelsItems = this.items.map(item => {
              const model = item
              if (model.name === data.name) {
                model.enabled = data.enabled
              }
              return model
            })
            this.items = modelsItems
            this.verifyModelsEnabled()
            const subCategoryModels = []
            this.selectedSubcategory = null
            this.getItemsSubcategory(subCategoryModels)
          } else {
            this.isBusySubCat = false
            const modelsItems = this.itemsSubcategory.map(item => {
              const model = item
              if (model.name === data.name) {
                model.enabled = data.enabled
              }
              return model
            })
            this.itemsSubcategory = modelsItems
          }
          if (data) {
            const variant = 'success'
            this.$bvToast.toast(`The model ${modelName} was correctly ${value ? 'enabled' : 'disabled'}`, {
              title: 'Model Manager',
              variant,
              solid: true,
            })
          }
          if (typeModel === TYPEMODELCATEGORY) {
            return this.dataModelManager()
          }
          return this.dataModelSubcategory()
        })
        .catch(() => {
          loadingProccessSpinner(true)
          this.errorMessage('Unable to switch the model. Something is wrong')
        })
    },
    dataModelManager() {
      if (this.selectedCategory) {
        store.dispatch('aiops-model-manager/dataModelManager', this.selectedCategory)
          .then(response => {
            const data = response
            this.models = data
            this.perPage = this.models.length
            if (this.models.length !== 0) {
              this.models.forEach(model => {
                if (model.enabled) {
                  this.optionsSubcategory = getSubcategories(model, MODELCLASS)
                }
              })
            }
            this.getItems(this.models)
            loadingProccessSpinner(false)
            loading(false)
          })
          .catch(() => {
            this.errorMessage('Unable to obtein the list of models')
            loadingProccessSpinner(false)
            loading(false)
          })
      } else {
        const data = []
        const models = data
        this.models = models
        this.perPage = this.models.length
        this.optionsSubcategory = []
        this.getItems(data)
        const subCategoryModels = []
        this.selectedSubcategory = null
        this.getItemsSubcategory(subCategoryModels)
        loading(false)
      }
    },
    dataModelSubcategory() {
      const models = this.items
      const modelActive = models.find(item => item.enabled === true)
      const { name } = modelActive
      this.modelName = name
      const payloadModelSubcategory = {
        modelName: this.modelName,
        modelClass: removeAccents(this.selectedSubcategory.replace(' ', '%20').replace('/', '%2F')),
      }
      if (this.selectedSubcategory) {
        store.dispatch('aiops-model-manager/dataModelSubcategory', payloadModelSubcategory)
          .then(response => {
            const data = response
            this.getItemsSubcategory(data)
          })
          .catch(() => {
            this.errorMessage('Unable to obtain the list of models in the subcategory')
          })
      } else {
        const subCategoryModels = []
        this.getItemsSubcategory(subCategoryModels)
      }
    },
    getAssignmentGroup() {
      store.dispatch('productAiops/dataAssignmentGroup')
        .then(response => {
          const data = response
          const selectData = data.map(group => ({ value: group.id, text: group.name }))
          const newAssignmentGroup = [
            { value: null, text: 'Select resolution group' },
            ...selectData,
          ]
          this.optionsGroup = newAssignmentGroup
          return this.dataModelManager()
        })
        .catch(() => {
          this.errorMessage('Unable to obtain the list of Resolution groups.')
          return this.dataModelManager()
        })
    },
    getItems(data) {
      this.items = data
      return this.items
    },
    getItemsSubcategory(data) {
      this.itemsSubcategory = data
      loadingProccessSpinner(false)
      return this.itemsSubcategory
    },
    verifyTicket() {
      return this.items.length === 0
    },
    verifyTicketSubcategory() {
      return this.itemsSubcategory.length === 0
    },
    verifyModelsEnabled() {
      return this.items.some(item => item.enabled === true)
    },
    onRowSelected(row) {
      this.selected = row
      // eslint-disable-next-line no-underscore-dangle
      if (this.selected._showDetails) {
        // eslint-disable-next-line dot-notation
        this.selected['_showDetails'] = false
      } else {
        this.$set(this.selected, '_showDetails', true)
      }
    },
    onRowSelectedSubcategory(row) {
      this.selectedSubCategory = row
      // eslint-disable-next-line no-underscore-dangle
      if (this.selectedSubCategory._showDetails) {
        // eslint-disable-next-line dot-notation
        this.selectedSubCategory['_showDetails'] = false
      } else {
        this.$set(this.selectedSubCategory, '_showDetails', true)
      }
    },
    activeModel() {
      this.activeButton = !this.activeButton
    },
    errorRequest() {
      store.dispatch('productAiops/errorRequest')
    },
    errorMessage(message = 'Something was wrong') {
      const variant = 'warning'
      this.$bvToast.toast(message, {
        title: 'Classification correction',
        variant,
        solid: true,
      })
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.aiops_color_box {
  background-color: #eef7f9;
}

.backlogged {
  background-color: rgb(75, 38, 38);
}

::v-deep {
  .colorHead {
    background: #f6fafb;
    font-size: 0.77vw;
    color: #3E7282;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  .colorBody {
    background: #ffffff !important;
    border-bottom: 2px solid blue;
    font-size: 0.77vw;
    color: #6F9CA8;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  .colorHeadDetail {
    // background-color: #f3f2f7;
    border-top: #ebe9f1 solid 2px !important;
    border-left: #ebe9f1 solid 2px !important;
    border-right: #ebe9f1 solid 2px !important;
    color: #6f9ca8;
    font-family: "Open Sans", Helvetica, Arial, serif;
    font-weight: 500;
    vertical-align: center;
  }

  .colorBodyDetail {
    // background-color: #f3f2f7;
    border-top: #ebe9f1 solid 2px !important;
    border-left: #ebe9f1 solid 2px !important;
    border-right: #ebe9f1 solid 2px !important;
    color: #6f9ca8;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  b-pagination .page-item .active .page-link {
    border-radius: 5rem;
    background-color: #12a71e !important;
  }

  .table tbody tr {
    height: 55px !important;
  }

  .aiops_icon_on {
    color: green;
  }

  .aiops_model_icon_trash {
    color: rgba(240, 70, 65, 1);
  }

  .aiops_model_table_col_action {
    text-align: right;
  }

  .aiops_Models__detail_align_inline {
    display: inline-block;
  }

  .aiops_Models__sliderBar {
    margin-right: 5px !important;
  }

  .aiops_Models__sliderBar_value {
    margin-left: 5px !important;
    border-top: #8de41b solid 1px !important;
  }

  p .aiops_model_value_slider {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .aiops_slider {
    background-color: #54db06;
  }

  .table th:last-child, .b-table td:last-child {
    text-align: right !important;
  }

  .tableDetailModel {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddedee;
  }

  .tableDetailModelStyle {
    border: 1px solid #021213 !important;
  }

  .colorHead {
    background: #f6fafb !important;
    color: #3E7282;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

}
</style>
