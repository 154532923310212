<template>
  <b-modal
    id="modal-aiops"
    centered
    hide-footer
    title-class="aiops-modal-title"
    header-class="aiops-modal-header"
    :hideHeaderClose="hideCloseButton"
    :noCloseOnBackdrop="evoidCloseOnBackdrop"
    :size="sizeModal"
  >
    <template
      #modal-title
    >
      <b-row
        align-h="end"
        class="aiops-content-title-modal"
      >
        <b-img
          class="aiops-logo-modal-component"
          :src="logo"
        />
        <span
          v-if="title"
          class="align-middle aiops-title-modal-component aiops_label_18_3e7282_bold"
        >
          {{ title }}
        </span>
      </b-row>
    </template>
    <slot name="body" />
  </b-modal>
</template>

<script>
import {
  BModal,
  BCardText,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BCardCode,
    BModal,
    BCardText,
    BRow,
    BCol,
    BImg,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    logo: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/images/logo/inetum_logo.svg'),
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    evoidCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    sizeModal: {
      type: String,
      default: 'lg',
    },
  },
  data() {
    return {
    }
  },
}
</script>
<style lang="scss">
.aiops-modal-header {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--catskill-white) !important;
  border-bottom: 1px solid var(--opal) !important;
  padding: 18px 24px;
  & .close {
      margin: 0;
      background-color: var(--catskill-white) !important;
      box-shadow: none !important;
      transform: none !important;
      font-size: 30px !important;
      color: var(--bismark);
  }
}
.aiops-modal-title {
    color: var(--bismark);
    padding: 0;
}
.aiops-content-title-modal {
  position: relative;
}
.aiops-logo-modal-component {
  position: sticky;
  background-color: transparent;
  padding-left: 15px;
  height: 21px;
  width: auto;
  vertical-align: middle;
}
.aiops-title-modal-component {
  position: absolute;
  left: 443px;
  right: 443px;
  width: 300px;
  padding: 0;
}

.modal-lg {
  max-width: 1142px !important;
}
</style>
