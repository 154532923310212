import useJwt from '@/auth/aiops/useJwt'
import utilsAiops from '@/@core/utils/aiops/utils'

const { formatDate } = utilsAiops()

export default {
  namespaced: true,
  state: {
    items: [],
    modelPerPage: undefined,
  },
  getters: {
    getItems(state) {
      return state.items
    },
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload
    },
  },
  actions: {
    dataModelManager({ commit }, area) {
      return new Promise((resolve, reject) => {
        useJwt.getModelManager({ area })
          .then(response => {
            const { data } = response
            const models = data
            const items = []
            models.forEach(item => {
              const dataTable = {
                name: item.name,
                last_trained_on: formatDate(item.lastTrainedOn),
                accuracy: item.accuracy,
                enabled: item.enabled,
                classes: item.classes,
                _rowVariant: 'light',
              }
              items.push(dataTable)
            })
            commit('setItems', items)
            resolve(items)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dataModelSubcategory({ commit }, payloadModelSubcategory) {
      const { modelName, modelClass } = payloadModelSubcategory
      return new Promise((resolve, reject) => {
        useJwt.getModelSubcategory(modelName, modelClass)
          .then(response => {
            const { data } = response
            const models = data
            const items = []
            models.forEach(item => {
              const dataTable = {
                name: item.name,
                last_trained_on: formatDate(item.lastTrainedOn),
                accuracy: item.accuracy,
                enabled: item.enabled,
                classes: item.classes,
                _rowVariant: 'light',
              }
              items.push(dataTable)
            })
            commit('setItems', items)
            resolve(items)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
